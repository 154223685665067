import styled from "styled-components";

export const Parent = styled.div`
  width: 40rem;
  padding: 0 2.5rem 0 2.5rem;
  box-sizing: border-box;
  @media (max-width: 425px) {
    width: 100vw;
    padding: 0 0.5rem 0 0.5rem;
  }
`;
