import styled from "styled-components";

export const Impact = styled.div((props) => ({
  color: props.color,
  padding: "0.5rem",
  height: "75px",
}));

export const ResultContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 1rem 0 1rem;
  line-height: 1.5rem;
`;

export const TextContainer = styled.div`
  display: flex;
  width: 116px;
  flex-direction: column;
  justify-content: center;
`;

export const UnitText = styled.p`
  font-size: 1rem;
`;

export const ScoreText = styled.p`
  font-weight: bold;
`;

export const Block = styled.div`
  border: 0.5rem solid green;
  border-radius: 5rem;
  padding: 1.5rem;
  max-width: 45%;
  position: relative;
  left: 4rem;
  height: 15rem;
  background-color: #000;
`;

export const InBlock = styled.div`
  display: flex;
`;

export const Icon = styled.img`
  height: 2.5rem;
  max-width: 2.5rem;
  max-height: 2.5rem;
  object-fit: contain;
`;

export const BigIcon = styled.img`
  width: 4.5rem;
  max-width: 4.5rem;
  max-height: 4.5rem;
  object-fit: contain;
  @media (max-width: 425px) {
    width: 2.5rem;
    max-width: 2.5rem;
    max-height: 2.5rem;
  }
  @media (max-width: 400px) {
    width: 4.5rem;
    max-width: 4.5rem;
    max-height: 4.5rem;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: #222;
  border-radius: 2rem;
  text-align: center;
  height: 15rem;
  width: 15rem;
  user-select: none;
  cursor: default;
  @media (max-width: 425px) {
    height: 100%;
    width: 100%;
  }
  @media (max-width: 400px) {
    height: 15rem;
    width: 15rem;
  }
`;

export const TextEquipment = styled.p`
  font-size: 12.4px;
  line-height: 0.8rem;
  width: 3rem;
`;
