import styled from "styled-components";

export const Title = styled.h2`
  font-size: 30px;
  font-weight: bold;
`;

export const TitleResult = styled.h2`
  font-size: 40px;
  font-weight: bold;
  margin: 1rem 0 1rem 0;
  grid-column: 1 / span 2;
`;

export const Text = styled.p`
  line-height: 20px;
  font-size: 0.9rem;
  margin-top: 1rem;
`;

export const TextInput = styled.p``;

export const SubTitle = styled.p`
  font-size: 1.5rem;
  margin: 2rem 0.5rem;
  @media (max-width: 400px) {
    font-size: 1rem;
  }
`;

export const ImportantWord = styled.span`
  font-style: italic;
  color: #6cd8e5;
`;
