import React from "react";
import Icons from "./Icons";

import * as S from "../styles/IconDevices";

export const IconDevices = ({
  device,
  listDevices,
  setListDevices,
  setDisplayDevices,
}) => {
  const handleClick = () => {
    setDisplayDevices([]);
    setListDevices([...listDevices, device]);
  };

  return (
    <S.Container onClick={handleClick}>
      <S.Icon alt={`icon ${device.name}`} src={Icons(device.name)} />
      <S.Text>{device.name}</S.Text>
    </S.Container>
  );
};
