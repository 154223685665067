import React from "react";
import { Helmet } from "react-helmet-async";
import { CircleLoader } from "react-spinners";
import styled from "styled-components";
import A from "../assets/A.jpg";
import B from "../assets/B.jpg";
import C from "../assets/C.jpg";
import D from "../assets/D.jpg";
import E from "../assets/E.jpg";

export const Note = ({ note }) => {
  const LoaderContainer = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  `;

  const Loading = styled.p`
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 2rem;
  `;

  const title = `I obtained a "${note}" EcoScore !"`;
  const img =
    note === "A"
      ? A
      : note === "B"
      ? B
      : note === "C"
      ? C
      : note === "D"
      ? D
      : E

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{title}</title>
        <meta name="title" content={`I obtained an eco score of ${note}`} />
        <meta
          name="description"
          content={`Do like me and calculate your ecological score, personally I got a ${note}!`}
        />
        <meta
          name="keywords"
          content="Ecology, Green, GreenPhotographer, Photographer, EnvironmentallyFriendly."
        />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="author" content="Cyme inc" />
        <meta name="robots" content="index, nofollow" />
        <meta
          http-equiv="refresh"
          content="2;url=https://cyme.io/footprint-calculator-photographer-dev/"
        />

        {/* Twitter */}

        <meta name="twitter:card" content="summary"></meta>
        <meta name="twitter:site" content="@CymePhotography" />
        <meta name="twitter:creator" content="@CymePhotography" />
        <meta property="og:url" content={`https://eco.cyme.io/`} />
        <meta
          property="og:title"
          content={`I obtained an eco score of ${note}`}
        />
        <meta
          property="og:description"
          content={`Do like me and calculate your ecological score, personally I got a ${note}! Using this calculator you will be able to know your ecological impact as a photographer and you will even be able to know how to reduce your pollution`}
        />
        <meta property="og:image" content={`https://eco.cyme.info${img}`} />
        <meta
          property="og:image:secure_url"
          content={`https://eco.cyme.info${img}`}
        />
        <meta property="og:image:type" content="image/jpg" />
        <meta property="og:image:alt" content={`An ${note} EcoScore`} />

        {/* Facebook */}

        <meta
          property="og:title"
          content={`I obtained an eco score of ${note}`}
        />
        <meta property="og:image" content={`https://eco.cyme.info${img}`} />
        <meta property="og:url" content={`https://eco.cyme.io/`} />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content={`Do like me and calculate your ecological score, personally I got a ${note}! Using this calculator you will be able to know your ecological impact as a photographer and you will even be able to know how to reduce your pollution`}
        />
        <meta
          name="keywords"
          content="Ecology, Green, GreenPhotographer, Photographer, EnvironmentallyFriendly."
        />
      </Helmet>
      <LoaderContainer>
        <CircleLoader color={"#6ed8e5"} loading={true} size={200} />
        <Loading>Loading...</Loading>
      </LoaderContainer>
    </>
  );
};
