import React from "react";
import { Location } from "../styles/AboutPhoto";

export const SelectRegion = ({ setOperator }) => {
  const handleChange = ({ target }) => {
    setOperator(target.value);
  };
  return (
    <Location onChange={handleChange}>
      <option value="ASIA">Asia Pacific</option>
      <option value="CHINA">China</option>
      <option value="EU27">Europe</option>
      <option value="FR">France</option>
      <option value="DE">Germany</option>
      <option value="IT">Italia</option>
      <option value="INDIA">India</option>
      <option value="JAPAN">Japan</option>
      <option value="ES">Spain</option>
      <option value="UK">United Kingdom</option>
      <option value="USA">USA</option>
    </Location>
  );
};
