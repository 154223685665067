import React from "react";
import { Down } from "../../svg/Down";

export const Decrease = ({ decrease, type }) => {
  const icon = decrease ? <Down /> : null;
  const percentage = type === "global" ? "%" : null;
  return (
    <>
      {icon}
      <p>
        {decrease ? decrease : 0}
        {percentage}
      </p>
    </>
  );
};
