import styled from "styled-components";

export const Icon = styled.img`
  object-fit: contain;
  height: 3.5rem;
  width: 3.5rem;
`;

export const Text = styled.p`
  font-size: 1rem;
  line-height: 2rem;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 5.3rem;
  align-items: center;
  border-radius: 0.5rem;
  transition: 150ms;
  &:hover {
    background-color: #222;
  }
`;
