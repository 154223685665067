import React from "react";

export const Down = ({ height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      aria-hidden="true"
      role="img"
      width={height ?? "1em"}
      height={height ?? "1em"}
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 16 16"
    >
      <g fill={color ?? "currentColor"}>
        <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
      </g>
    </svg>
  );
};
