import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { ListDevices } from "./ListDevices";
import { UserLevel } from "./UserLevel";
import resultCalculation from "../hooks/resultCalculation";

import { Base } from "../styles/Base";
import { Parent } from "../styles/Parent";

import { DEVICE } from "../utils/devices";
import { Id } from "../utils/Id";
import { AboutPhoto } from "./AboutPhoto";
import { DisplayResult } from "./Result";
import { Score } from "./Score";
import { Tips } from "./Tips";

export const Body = () => {
  const [listDevices, setListDevices] = useState([
    new DEVICE(Id(), "smartphone", "user", 3, 1, 1),
  ]);
  const [numberPhotos, setNumberPhotos] = useState(2500);
  const [operator, setOperator] = useState("ASIA");
  const [results, setResults] = useState(null);
  const [refresh, setRefresh] = useState(false);

  window.parent.postMessage(document.getElementById("root").scrollHeight, "*");

  useEffect(() => {
    resultCalculation(listDevices, numberPhotos, operator, setResults);
    window.parent.postMessage(
      document.getElementById("root").scrollHeight,
      "*"
    );
  }, [listDevices, numberPhotos, operator, refresh]);

  return (
    <Base>
      <Helmet prioritizeSeoTags>
        <title>Impact Photographer Ecology</title>
        <meta name="title" content="Impact Photographer Ecology" />
        <meta
          name="description"
          content="Calculator to know our ecological impact as a photographer"
        />
        <meta
          name="keywords"
          content="Ecology, Green, GreenPhotographer, Photographer, EnvironmentallyFriendly."
        />
        <meta name="robots" content="index, follow" />
        <meta name="theme-color" content="#000000" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="author" content="Cyme inc" />

        <meta property="og:title" content="Impact Photographer Ecology" />
        <meta
          property="og:image"
          content="https://cyme.io/wp-content/uploads/2019/07/CYME-BLANC@0.05x.png"
        />
        <meta
          property="og:url"
          content="https://eco.cyme.info/parent_index.html"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="Calculator to know our ecological impact as a photographer"
        />
        <meta
          name="keywords"
          content="Ecology, Green, GreenPhotographer, Photographer, EnvironmentallyFriendly."
        />
      </Helmet>
      <Parent>
        <UserLevel
          setListDevices={setListDevices}
          setNumberPhotos={setNumberPhotos}
        />
        <ListDevices
          listDevices={listDevices}
          setListDevices={setListDevices}
          setRefresh={setRefresh}
          refresh={refresh}
          results={results}
        />
        <AboutPhoto
          numberPhotos={numberPhotos}
          listDevices={listDevices}
          setListDevices={setListDevices}
          setNumberPhotos={setNumberPhotos}
          operator={operator}
          setOperator={setOperator}
          results={results}
        />
        <DisplayResult results={results} />
        <Score results={results} />
        <Tips
          numberDuplicate={numberPhotos * 0.2}
          decreaseDuplicate={results ? results.duplicates : null}
          decreaseDevice={results ? results.deviceSaved : null}
          init={results}
        />
      </Parent>
    </Base>
  );
};
