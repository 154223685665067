import { host } from "../utils/host";
import sharingOut from "./sharingOut";

const resultCalculation = async (
  deviceSelected,
  numberPhotos,
  operator,
  setResults
) => {
  const nbrPhoto =
    numberPhotos === 0 ? 1 : numberPhotos === null ? 1 : numberPhotos;
  const listDevices = deviceSelected.filter(
    (device) => device.lifeTime > 0 && device.lifeTime !== null
  );
  const backupEnable = deviceSelected.find(
    (device) => device.class === "remote"
  )
    ? true
    : false;

  const transmissionOperator =
    operator === "DE" ||
    operator === "IT" ||
    operator === "ES" ||
    operator === "UK"
      ? "EU27"
      : operator === "CHINA" ||
        operator === "JAPAN" ||
        operator === "INDIA" ||
        operator === "ASIA"
      ? "USA"
      : operator;

  sharingOut(listDevices);

  const response = await fetch(`${host}/total`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      masterResolution: 4096,
      previewResolution: 4096,
      hdAnyDevice: true,
      terminalOperator: operator,
      transmissionOperator: `fixe${transmissionOperator}`,
      datacenterOperator: operator,
      durationSimulation: 12,
      numberPhotos: nbrPhoto,
      backupEnable,
      deviceSelected: listDevices,
    }),
  });

  return setResults(await response.json());
};

export default resultCalculation;
