import React from "react";
import * as S from "../../styles/Tips";
import { Decrease } from "./ImprovementScore/Decrease";
import { ImpactScore } from "./ImprovementScore/ImpactScore";

export const Improvement = ({
  numberDuplicate,
  decrease,
  init,
  title,
  setSelected,
}) => {
  const display =
    title === "duplicates" ? (
      <S.TipsContainer>
        <S.ArrowBack side={"left"} onClick={() => setSelected(0)} />
        <S.TipsTitle>Duplicates</S.TipsTitle>
        <S.TipsText>
          On average, a photographer has around 20% of duplicates in his photo
          catalogs.
          <br /> By eliminating your{" "}
          <S.TipsImportant>
            {numberDuplicate.toFixed(0)} duplicates
          </S.TipsImportant>
          , you will be able to reduce your environmental impact by:
        </S.TipsText>
        <S.ScoreContainer>
          <S.GlobalScore>
            <Decrease
              decrease={decrease ? decrease.global.toFixed(2) : null}
              type="global"
            />
          </S.GlobalScore>
          <ImpactScore
            unit="co2"
            initScore={init ? init.co2.total.toFixed(2) : null}
            decrease={decrease ? decrease.co2 : null}
          />
          <ImpactScore
            initScore={init ? init.mj.total.toFixed(2) : null}
            decrease={decrease ? decrease.mj : null}
          />
        </S.ScoreContainer>
        <S.ArrowBack side={"right"} onClick={() => setSelected(50)} />
      </S.TipsContainer>
    ) : (
      <S.TipsContainer>
        <S.ArrowBack side={"left"} onClick={() => setSelected(0)} />
        <S.TipsTitle>{title}</S.TipsTitle>
        <S.TipsText>
          What pollutes the most is the early change of our various equipment,
          by keeping your equipment for even 1 year more you will save this:
        </S.TipsText>
        <S.ScoreContainer>
          <S.GlobalScore>
            <Decrease
              decrease={decrease ? decrease.global.toFixed(2) : null}
              type="global"
            />
          </S.GlobalScore>
          <ImpactScore
            unit="co2"
            initScore={init ? init.co2.total.toFixed(2) : null}
            decrease={decrease ? decrease.co2 : null}
          />
          <ImpactScore
            initScore={init ? init.mj.total.toFixed(2) : null}
            decrease={decrease ? decrease.mj : null}
          />
        </S.ScoreContainer>
        <S.ArrowBack side={"right"} onClick={() => setSelected(50)} />
      </S.TipsContainer>
    );
  return <>{display}</>;
};
