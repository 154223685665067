import React, { useState } from "react";
import * as S from "../styles/Score";
import img from "../assets/Score.png";

import share from "../utils/share";
import { Twitter } from "./svg/Twitter";
import { Facebook } from "./svg/Facebook";
import { Linkedin } from "./svg/Linkedin";

export const Score = ({ results }) => {
  const [shareButton, setShareButton] = useState(<p>Share it</p>);
  const [backgroundColor, setBackgroundColor] = useState("#6ed8e5");
  const handleMouseIn = () => {
    setShareButton(
      <>
        <Twitter
          share={() => share(results.score, "twitter")}
          setBackgroundColor={setBackgroundColor}
        />
        <Facebook
          share={() => share(results.score, "facebook")}
          setBackgroundColor={setBackgroundColor}
        />
        <Linkedin
          share={() => share(results.score, "linkedin")}
          setBackgroundColor={setBackgroundColor}
        />
      </>
    );
  };

  const handleMouseDown = () => {
    setShareButton(<p>Share it</p>);
  };
  return (
    <S.Container>
      <S.Display>
        <S.TextContainer>
          <S.Title>REPARTITION</S.Title>
          <S.Text>
            {results
              ? `You obtained a ${results.score} in eco score `
              : "You have not yet obtained your eco score"}{" "}
          </S.Text>
        </S.TextContainer>
        <S.ScoreContainer>
          <S.Img alt="backgroud score" src={img} />
          <S.Score>{results ? results.score : null}</S.Score>
        </S.ScoreContainer>
      </S.Display>
      <S.Share
        onMouseEnter={handleMouseIn}
        onMouseLeave={handleMouseDown}
        backgroundColor={backgroundColor}
      >
        {shareButton}
      </S.Share>
    </S.Container>
  );
};
