import { Body } from "./components/Body";
import { Note } from "./pages/Note";
import { BrowserRouter as Router, Route } from "react-router-dom";

function App() {
  return (
    <>
      <Router>
        <Route path="/" exact component={Body} />
        <Route
          path="/a"
          render={() => {
            return <Note note={"A"} />;
          }}
        />
        <Route
          path="/b"
          render={() => {
            return <Note note={"B"} />;
          }}
        />
        <Route
          path="/c"
          render={() => {
            return <Note note={"C"} />;
          }}
        />
        <Route
          path="/d"
          render={() => {
            return <Note note={"D"} />;
          }}
        />
        <Route
          path="/e"
          render={() => {
            return <Note note={"E"} />;
          }}
        />
      </Router>
    </>
  );
}

export default App;
