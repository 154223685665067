import React from "react";
import { Result } from "../styles/Device";
import { Card } from "./Results/Card";

import CO2 from "../assets/EcoWebSite/CO2.png";
import H2O from "../assets/EcoWebSite/Drop.png";
import MJ from "../assets/EcoWebSite/Lightening.png";
import SB from "../assets/EcoWebSite/Rock.png";
import Car from "../assets/EcoWebSite/Car.png";
import BottleWater from "../assets/EcoWebSite/Bottle.png";
import Fridge from "../assets/EcoWebSite/Fridge.png";
import OilBarrel from "../assets/EcoWebSite/OilBarrel.png";

export const ResultSection = ({ results, clarify }) => {
  return (
    <Result>
      <div>
        <Card
          name="co2"
          impact={results ? results.co2[clarify] : 0}
          unit="kg Eq Co2"
          img={CO2}
          type="equipment"
        ></Card>
        <Card
          name="car"
          impact={results ? results.co2[`${clarify}Equivalent`] : 0}
          unit="km"
          img={Car}
          type="equipment"
        ></Card>
      </div>
      <div>
        <Card
          name="h2o"
          impact={results ? results.h2o[clarify] : 0}
          unit="m3"
          img={H2O}
          type="equipment"
        ></Card>
        <Card
          name="water"
          impact={results ? results.h2o[`${clarify}Equivalent`] : 0}
          unit="Bottles (1.5L)"
          img={BottleWater}
          type="equipment"
        ></Card>
      </div>
      <div>
        <Card
          name="mj"
          impact={results ? results.mj[clarify] : 0}
          unit="Mj"
          img={MJ}
          type="equipment"
        ></Card>
        <Card
          name="fridge"
          impact={results ? results.mj[`${clarify}Equivalent`] : 0}
          unit="Fridges (A+)"
          img={Fridge}
          type="equipment"
        ></Card>
      </div>
      <div>
        <Card
          name="sb"
          impact={results ? results.sb[clarify] : 0}
          unit="Sb"
          img={SB}
          type="equipment"
        ></Card>
        <Card
          name="OilBarrel"
          impact={results ? results.sb[`${clarify}Equivalent`] : 0}
          unit="Oil Barrel"
          img={OilBarrel}
          type="equipment"
        ></Card>
      </div>
    </Result>
  );
};
