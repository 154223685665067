import React from "react";
import * as Fonts from "../styles/Fonts";
import { IconUser } from "../styles/Input";

export const Level = ({ level, img }) => {
  return (
    <div>
      <IconUser alt={level} src={img}></IconUser>
      <Fonts.SubTitle>{level}</Fonts.SubTitle>
    </div>
  );
};
