import React, { useState, useEffect } from "react";
import { Improvement } from "./Carousel/Improvement";
import * as S from "../styles/Tips";

export const Tips = ({
  numberDuplicate,
  decreaseDuplicate,
  decreaseDevice,
  init,
}) => {
  const [selected, setSelected] = useState(0);
  const [hover, setHover] = useState(false);

  useEffect(() => {
    const slideAuto = setTimeout(() => {
      if (!hover) setSelected(selected === 0 ? 50 : 0);
    }, 10e3);
    return () => {
      clearTimeout(slideAuto);
    };
  });

  return (
    <S.Container
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => setHover(false)}
    >
      <S.Title>TIPS TO IMPROVE</S.Title>
      <S.Carousel>
        <S.Slider selected={selected}>
          <Improvement
            numberDuplicate={numberDuplicate}
            init={init}
            decrease={decreaseDuplicate}
            title="duplicates"
            setSelected={setSelected}
          />
          <Improvement
            numberDuplicate={numberDuplicate}
            init={init}
            decrease={decreaseDevice}
            title="save your devices !"
            setSelected={setSelected}
          />
        </S.Slider>
        <S.SlideButton>
          <S.Slide
            color={selected === 0 ? "#fff" : undefined}
            onClick={() => setSelected(0)}
          />
          <S.Slide
            color={selected === 50 ? "#fff" : undefined}
            onClick={() => setSelected(50)}
          />
        </S.SlideButton>
      </S.Carousel>
    </S.Container>
  );
};
