import React, { useState, useEffect } from "react";
import { DEVICE } from "../utils/devices";
import { Id } from "../utils/Id";

import { AddButton } from "./svg/Add";
import * as S from "../styles/AddDevice";
import { IconDevices } from "./IconDevices";

export const AddDevice = ({ setListDevices, listDevices }) => {
  const [displayDevices, setDisplayDevices] = useState([]);

  useEffect(() => {
    window.parent.postMessage(
      document.getElementById("root").scrollHeight,
      "*"
    );
  }, [displayDevices]);

  const handleClick = () => {
    if (displayDevices.length > 0) return setDisplayDevices([]);
    setDisplayDevices([
      new DEVICE(Id(), "computer", "user", 6, 1, 1),
      new DEVICE(Id(), "smartphone", "user", 3, 1, 1),
      new DEVICE(Id(), "tablet", "user", 4, 1, 1),
      new DEVICE(Id(), "laptop", "user", 5, 1, 1),
      new DEVICE(Id(), "screen", "user", 10, 1, 1),
      new DEVICE(Id(), "SSD", "local", 2, 1, 1),
      new DEVICE(Id(), "HDD", "local", 2, 1, 1),
    ]);
  };

  const displayButton =
    displayDevices.length === 0 ? (
      <S.Button onClick={handleClick}>
        <AddButton height="1.2em" />
        <S.Text>Add device</S.Text>
      </S.Button>
    ) : (
      <S.Close onClick={handleClick}>
        <AddButton height={"1.5em"} />
      </S.Close>
    );

  return (
    <S.Container>
      <S.ContainerDevice>
        {displayDevices.map((device) => {
          return (
            <IconDevices
              key={device.id}
              device={device}
              setDisplayDevices={setDisplayDevices}
              listDevices={listDevices}
              setListDevices={setListDevices}
            />
          );
        })}
      </S.ContainerDevice>
      {displayButton}
    </S.Container>
  );
};
