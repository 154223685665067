import React from "react";
import { SocialMediaIcon } from "../../styles/Score";

export const Facebook = ({ color, height, share, setBackgroundColor }) => {
  return (
    <SocialMediaIcon
      onClick={share}
      onMouseEnter={() => setBackgroundColor("#3b5998")}
      onMouseLeave={() => setBackgroundColor("#6ed8e5")}
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      aria-hidden="true"
      role="img"
      width={height ?? "1em"}
      height={height ?? "1em"}
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
    >
      <title>Share on Facebook</title>
      <path
        fill={color ?? "#fff"}
        fillRule="evenodd"
        d="M23 0H1a1 1 0 0 0-1 1v22a1 1 0 0 0 1 1h11.75v-9h-3v-3.75h3v-3c0-3.1 1.963-4.625 4.728-4.625c1.324 0 2.463.099 2.794.142v3.24l-1.917.001c-1.504 0-1.855.715-1.855 1.763v2.479h3.75L19.5 15h-3l.06 9H23a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1"
      />
    </SocialMediaIcon>
  );
};
