import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  border: 0.5rem solid green;
  border-radius: 5rem;
  padding: 3rem 2rem 0 2rem;
  margin-bottom: 2.5rem;
  @media (max-width: 425px) {
    border-radius: 2rem;
    padding: 1.5rem 1rem 0 1rem;
    margin-bottom: 2.5rem;
  }
`;

export const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
`;

export const TipsContainer = styled.div`
  padding: 1rem 0 2rem;
  background-color: #222;
  position: relative;
  width: 50%;
`;

export const TipsTitle = styled.h3`
  color: #6ed8e5;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-transform: capitalize;
`;

export const TipsText = styled.p`
  line-height: 1.1rem;
  padding: 0 1rem;
`;

export const TipsImportant = styled.span`
  font-weight: bold;
  font-size: 1rem;
  color: #6ed8e5;
`;

export const GlobalScore = styled.div`
  color: limegreen;
  font-size: 2rem;
  font-weight: bold;
  display: flex;
  padding: 0 1rem;
  column-gap: 1rem;
  @media (max-width: 425px) {
    font-size: 1.5rem;
    column-gap: 0.5rem;
    @media (max-width: 400px) {
      grid-column: 1 / span 2;
      justify-content: center;
    }
  }
`;

export const ImpactScore = styled.div`
  color: limegreen;
  font-size: 0.8rem;
  font-weight: bold;
  display: flex;
  column-gap: 0.2rem;
`;

export const IconImpact = styled.img`
  @media (max-width: 425px) {
    height: 3rem;
    width: 5.5rem;
    object-fit: contain;
  }
`;

export const InitScore = styled.p`
  font-size: 1.3rem;
  font-weight: bold;
  margin-top: 1rem;
  column-gap: 1rem;
  @media (max-width: 425px) {
    font-size: 1rem;
  }
`;

export const ImpactScoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ScoreContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 2rem;
  position: relative;
  @media (max-width: 400px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 1rem;
  }
`;

export const ArrowBack = styled.div((props) => ({
  position: "absolute",
  height: "100%",
  width: "30%",
  top: "0",
  zIndex: 999,
  left: props.side === "left" ? "0" : undefined,
  right: props.side === "right" ? "0" : undefined,
  transition: "150ms",
  cursor: "pointer",
  "&:hover": {
    boxShadow:
      props.side === "left"
        ? "inset 40px 0px 24px -30px #444"
        : "inset -40px 0px 24px -30px #444",
  },
}));

export const Carousel = styled.div`
  margin: 2rem 0.5rem;
  font-size: 0.9rem;
  border-radius: 1.5rem;
  overflow: hidden;
  position: relative;
`;

export const Slider = styled.div((props) => ({
  display: "-webkit-box",
  position: "relative",
  transition: "500ms",
  transform: `translateX(-${props.selected}%)`,
  top: 0,
  width: "200%",
  height: "100%",
}));

export const Slide = styled.div((props) => ({
  height: "0.7rem",
  width: " 0.67rem",
  border: "1px solid #fff",
  borderRadius: "50%",
  transition: "100ms",
  cursor: "pointer",
  backgroundColor: props.color,
  boxShadow: "inset 0px 0px 0px 2px #222",
  margin: "0 0.4rem",
  "&:hover": {
    backgroundColor: "#6ed8e5",
  },
}));

export const SlideButton = styled.div`
  position: absolute;
  display: flex;
  bottom: 0.8rem;
  width: 100%;
  justify-content: center;
`;
