const { default: styled } = require("styled-components");

export const Base = styled.div`
  background-color: #000000;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.25rem;
  font-family: 'Avenir', sans-serif;
`;
