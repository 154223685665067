import React, { useState } from "react";
import * as S from "../styles/Device";
import * as SE from "../styles/Equipments";
import {
  Lifetime,
  NameDevice,
  IconDevice,
  LifeContainer,
} from "../styles/Input";
import Icons from "./Icons";
import { RemoveButton } from "./svg/Remove";

export const Devices = ({
  device,
  setRefresh,
  listDevices,
  setListDevices,
}) => {
  const [lifeTime, setLifeTime] = useState(device.lifeTime);

  const handleChange = ({ target }) => {
    if (isNaN(Number(target.value))) return;
    if (!target.value) return setLifeTime(null);
    if (Number(target.value) > 99) return setLifeTime(99);
    if (Number(target.value) < 0) return setLifeTime(0);
    setLifeTime(Number(target.value));
    device.lifeTime = Number(target.value);
    setRefresh((refresh) => (refresh ? false : true));
  };

  const handleRemove = () => {
    const newList = listDevices.filter(
      (deviceSelected) => deviceSelected.id !== device.id
    );
    setListDevices(newList);
  };

  const displayDevice = () => {
    if (device.type || device.class === "remote") return null;
    return (
      <S.Container>
        <NameDevice>
          <IconDevice alt={device.name} src={Icons(device.name)}></IconDevice>
        </NameDevice>
        <LifeContainer htmlFor={`lifeTime${device.id}`}>
          <SE.TextInput>
            Renew every
            <Lifetime
              title="Lifetime of your device"
              id={`lifeTime${device.id}`}
              onChange={handleChange}
              type="number"
              min={1}
              max={99}
              value={lifeTime}
            ></Lifetime>
            {device.lifeTime > 1 ? "years" : "year"}
          </SE.TextInput>
        </LifeContainer>
        <RemoveButton handleClick={handleRemove} />
      </S.Container>
    );
  };

  return <>{displayDevice()}</>;
};
