import React from "react";

import CO2 from "../../../assets/EcoWebSite/CO2.png";
import MJ from "../../../assets/EcoWebSite/Lightening.png";
import { Decrease } from "./Decrease";
import * as S from "../../../styles/Tips";

export const ImpactScore = ({ initScore, decrease, unit }) => {
  const displayImpact =
    unit === "co2" ? (
      <>
        <S.IconImpact alt={"Co2 economy"} src={CO2} />
        <p>kg Eq Co2</p>
      </>
    ) : (
      <>
        <S.IconImpact alt={"Energy economy"} src={MJ} />
        <p>Mégajoule</p>
      </>
    );
  return (
    <S.ImpactScoreContainer>
      {displayImpact}
      <S.InitScore>{initScore ? initScore : 0}</S.InitScore>
      <S.ImpactScore>
        <Decrease decrease={decrease ? decrease.toFixed(2) : null} />
      </S.ImpactScore>
    </S.ImpactScoreContainer>
  );
};
