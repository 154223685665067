import styled from "styled-components";

export const Button = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  margin-bottom: 4rem;
  border: 1px solid #fff;
  border-radius: 0.7rem;
  padding: 0.5rem;
  margin-left: 2rem;
  cursor: pointer;
  user-select: none;
  transition: 150ms;
  &:hover {
    border-radius: 0.3rem;
  }
`;

export const Text = styled.p`
  margin-left: 1rem;
`

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  `;

export const ContainerDevice = styled.div`
  display: grid;
  background-color: #171717;
  border-radius: 1rem;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 2rem;
  justify-items: center;
  min-width: 265px;
  margin-bottom: 2rem;
`;

export const Close = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  transform: rotate(45deg);
`;
