import React from "react";
import { Card } from "./Results/Card";

import { ResultGlobal } from "../styles/Section";
import * as Result from "../styles/Result";
import { TitleResult } from "../styles/Fonts";

import CO2 from "../assets/EcoWebSite/CO2.png";
import H2O from "../assets/EcoWebSite/Drop.png";
import MJ from "../assets/EcoWebSite/Lightening.png";
import SB from "../assets/EcoWebSite/Rock.png";
import Car from "../assets/EcoWebSite/Car.png";
import BottleWater from "../assets/EcoWebSite/Bottle.png";
import Fridge from "../assets/EcoWebSite/Fridge.png";
import OilBarrel from "../assets/EcoWebSite/OilBarrel.png";

export const DisplayResult = ({ results }) => {
  return (
    <ResultGlobal>
      <TitleResult>YOUR ECOLOGICAL FOOTPRINT PER YEAR</TitleResult>
      <Result.Card>
        <Card
          impact={results ? results.co2.total : 0}
          name="co2"
          img={CO2}
          unit="kg Eq Co2"
        />
        <Card
          impact={results ? results.co2.totalEquivalent : 0}
          name={"km by car"}
          img={Car}
          unit="km by car"
        />
      </Result.Card>
      <Result.Card>
        <Card
          impact={results ? results.h2o.total : 0}
          name="h2o"
          img={H2O}
          unit="m3"
        />
        <Card
          impact={results ? results.h2o.totalEquivalent : 0}
          name={"Bottle of water"}
          img={BottleWater}
          unit="Bottles (1.5L)"
        />
      </Result.Card>
      <Result.Card>
        <Card
          impact={results ? results.mj.total : 0}
          name="mj"
          img={MJ}
          unit="Mj"
        />
        <Card
          impact={results ? results.mj.totalEquivalent : 0}
          name={"Fridge"}
          img={Fridge}
          unit="Fridges (A+)"
        />
      </Result.Card>
      <Result.Card>
        <Card
          impact={results ? results.sb.total : 0}
          name="sb"
          img={SB}
          unit="Sb"
        />
        <Card
          impact={results ? results.sb.totalEquivalent : 0}
          name={"Barrel of Petrol"}
          img={OilBarrel}
          unit="Oil Barrel"
        />
      </Result.Card>
    </ResultGlobal>
  );
};
