import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 0;
`;

export const Display = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 2rem;
  width: 100%;
  @media (max-width: 400px) {
    flex-direction: column;
    row-gap: 2rem;
  }
`;

export const TextContainer = styled.div`
  width: fit-content;
  height: fit-content;
  max-width: 50%;
`;

export const Title = styled.h3`
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;
export const Text = styled.p`
  font-size: 1.3rem;
  max-width: 200px;
  line-height: 1.4rem;
`;

export const ScoreContainer = styled.div`
  position: relative;
`;
export const Img = styled.img`
  height: 12rem;
`;
export const Score = styled.p`
  position: absolute;
  top: 5.5rem;
  right: 4rem;
  width: 2ch;
  font-size: 5rem;
  font-weight: bold;
  text-align: center;
`;

export const Share = styled.div((props) => ({
  backgroundColor: props.backgroundColor ?? "#6ed8e5",
  width: "15rem",
  height: "3.5rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  fontSize: "2.5rem",
  fontWeight: "bold",
  outline: "none",
  border: "none",
  borderRadius: "2.3rem",
  transition: "200ms",
  "&:hover": {
    borderRadius: "0.5rem",
    justifyContent: "space-around"
  }
}));

export const SocialMediaIcon = styled.svg`
  font-size: 2rem;
  transition: 100ms;
  &:hover {
    transform: scale(1.2);
  }
`;
