import React from "react";
import * as S from "../../styles/Remove";

export const RemoveButton = ({ height, color, handleClick }) => {
  return (
    <S.Icon
      onClick={handleClick}
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      aria-hidden="true"
      role="img"
      width={height ?? "1em"}
      height={height ?? "1em"}
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
    >
      <g fill="none">
        <path
          d="M8 11a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2H8z"
          fill={color ?? "#fff"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23 12c0 6.075-4.925 11-11 11S1 18.075 1 12S5.925 1 12 1s11 4.925 11 11zm-2 0a9 9 0 1 1-18 0a9 9 0 0 1 18 0z"
          fill={color ?? "#fff"}
        />
      </g>
    </S.Icon>
  );
};
