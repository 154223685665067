import styled from "styled-components";

export const StandardSection = styled.div`
  position: relative;
  border: 0.5rem solid green;
  border-radius: 5rem;
  padding: 3rem 2rem 0 2rem;
  margin-bottom: 2.5rem;
  @media (max-width: 425px) {
    border-radius: 2rem;
    padding: 1.5rem 1rem 0 1rem;
    margin-bottom: 2.5rem;
  }
`;

export const ResultGlobal = styled.div`
  position: relative;
  border: 0.5rem solid green;
  border-radius: 5rem;
  padding: 2.5rem;
  display: grid;
  grid-template-columns: repeat(2, 15rem);
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;
  justify-items: center;
  justify-content: center;
  margin-bottom: 2.5rem;
  @media (max-width: 425px) {
    grid-template-columns: repeat(2, 10rem);
    grid-template-rows: repeat(3, 10rem);
    border-radius: 2rem;
    padding: 1.5rem 1rem;
    margin-bottom: 2.5rem;
  }
  @media (max-width: 400px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
