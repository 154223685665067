import React, { useState, useEffect } from "react";
import { Level } from "./Level";

import * as Fonts from "../styles/Fonts";
import * as Input from "../styles/Input";
import { StandardSection } from "../styles/Section";

import Beginner from "../assets/beginner.png";
import Amateur from "../assets/amateur.png";
import Professional from "../assets/professional.png";

import { DEVICE } from "../utils/devices";
import { Id } from "../utils/Id";

export const UserLevel = ({ setListDevices, setNumberPhotos }) => {
  const [lvl, setLvl] = useState(2500);

  useEffect(() => {
    displayUserLvl();
  });

  const handleClick = (e) => {
    if (e.target.localName !== "input") return;
    e.target.checked = true;
    return setLvl(Number(e.target.value));
  };

  const addDevice = (e) => {
    setListDevices(
      devicesProfil.find((user) => user.profil === e.target.id).device
    );
    setNumberPhotos(
      devicesProfil.find((user) => user.profil === e.target.id).numberPhotos
    );
  };

  const displayUserLvl = () => {
    return (
      <Input.ChoiceLvl>
        <Input.UserLvl
          htmlFor="Amateur"
          opacity={lvl === 2500 ? 1 : 0.5}
          onClick={handleClick}
        >
          <Level
            level="Amateur"
            img={Beginner}
            setNumberPhotos={setNumberPhotos}
            setListDevices={setListDevices}
          />
          <Input.Radio
            hidden
            name="userLvl"
            id="Amateur"
            type="radio"
            value={2500}
            defaultChecked={true}
            onClick={addDevice}
          ></Input.Radio>
        </Input.UserLvl>
        <Input.UserLvl
          htmlFor="Passionate"
          opacity={lvl === 100000 ? 1 : 0.5}
          onClick={handleClick}
        >
          <Level
            level="Passionate"
            img={Amateur}
            setNumberPhotos={setNumberPhotos}
            setListDevices={setListDevices}
          />
          <Input.Radio
            hidden
            name="userLvl"
            id="Passionate"
            type="radio"
            value={100000}
            onClick={addDevice}
          ></Input.Radio>
        </Input.UserLvl>
        <Input.UserLvl
          htmlFor="Professionnal"
          opacity={lvl === 250000 ? 1 : 0.5}
          onClick={handleClick}
        >
          <Level
            level="Professionnal"
            img={Professional}
            setNumberPhotos={setNumberPhotos}
            setListDevices={setListDevices}
          />
          <Input.Radio
            hidden
            name="userLvl"
            id="Professionnal"
            type="radio"
            value={250000}
            onClick={addDevice}
          ></Input.Radio>
        </Input.UserLvl>
      </Input.ChoiceLvl>
    );
  };

  const amateur = {
    profil: "Amateur",
    numberPhotos: 2500,
    device: [new DEVICE(Id(), "smartphone", "user", 3, 1, 1)],
  };
  const passionate = {
    profil: "Passionate",
    numberPhotos: 100000,
    device: [
      new DEVICE(Id(), "smartphone", "user", 3, 1, 1),
      new DEVICE(Id(), "computer", "user", 6, 1, 1),
      new DEVICE(Id(), "tablet", "user", 4, 1, 1),
    ],
  };
  const professionnal = {
    profil: "Professionnal",
    numberPhotos: 250000,
    device: [
      new DEVICE(Id(), "smartphone", "user", 3, 1, 1),
      new DEVICE(Id(), "computer", "user", 6, 1, 1),
      new DEVICE(Id(), "tablet", "user", 4, 1, 1),
      new DEVICE(Id(), "laptop", "user", 5, 1, 1),
      new DEVICE(Id(), "screen", "user", 10, 1, 1),
      new DEVICE(Id(), "SSD", "local", 2, 1, 1),
    ],
  };

  const devicesProfil = [amateur, passionate, professionnal];

  return (
    <StandardSection>
      <Fonts.Title>Who is your inner photographer ?</Fonts.Title>
      <Fonts.Text>
        {" "}
        Beginner, passionate amateur or professional photographer? <br /> What
        best describes you?{" "}
      </Fonts.Text>
      <Input.InputLvlGlobal>{displayUserLvl()}</Input.InputLvlGlobal>
    </StandardSection>
  );
};
