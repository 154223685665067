import React from "react";
import { HelmetProvider } from "react-helmet-async";
import "./styles/reset.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { hydrate, render } from "react-dom";

const app = (
  <HelmetProvider>
    <App />
  </HelmetProvider>
);

 
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(app, rootElement);
} else {
  render(app, rootElement);
}

reportWebVitals();
