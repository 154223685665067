import React, { useState, useEffect } from "react";
import { SelectRegion } from "./SelectRegion";
import { DEVICE } from "../utils/devices";
import { Id } from "../utils/Id";

import { StandardSection } from "../styles/Section";
import { Title, Text, TextInput } from "../styles/Fonts";
import * as S from "../styles/AboutPhoto";

import cloud from "../assets/EcoWebSite/cloud.png";
import PhotoTotal from "../assets/EcoWebSite/PhotosCopy.png";
import Geo from "../assets/EcoWebSite/Geo.png";
import { ResultSection } from "./ResultSection";

export const AboutPhoto = ({
  numberPhotos,
  setNumberPhotos,
  listDevices,
  setListDevices,
  operator,
  setOperator,
  results,
}) => {
  const defaultCloud = listDevices.find((device) => device.type) ? true : false;
  const [storageType, setStorageType] = useState(null);
  const HDDCheck = listDevices.find(
    (device) => device.class === "remote" && device.name === "HDD"
  )
    ? true
    : false;
  const SSDCheck = listDevices.find(
    (device) => device.class === "remote" && device.name === "SSD"
  )
    ? true
    : false;

  useEffect(() => {
    const optionSelected = defaultCloud
      ? "cloud"
      : HDDCheck
      ? "HDD"
      : SSDCheck
      ? "SSD"
      : null;
    setStorageType(optionSelected);
  }, [defaultCloud, HDDCheck, SSDCheck, results]);

  const device = (name) => {
    if (name === "cloud")
      return new DEVICE(Id(), name, "remote", 3, 24, 1, operator, "HDD");
    return new DEVICE(Id(), name, "remote", 5, 24, 1, operator);
  };

  const handleChangePhoto = (e) => {
    if (e.target.value === "") return setNumberPhotos(null);
    if (isNaN(Number(e.target.value))) return;
    setNumberPhotos(Number(e.target.value));
  };

  const handleClickStorage = (e) => {
    if (e.target.localName !== "input") return;
    const allDevices = listDevices.filter(
      (device) => device.class !== "remote"
    );

    if (e.target.value === storageType)
      return (
        setListDevices(allDevices),
        setStorageType(null),
        (e.target.checked = false)
      );

    setListDevices([...allDevices, device(e.target.value)]);
    setStorageType(e.target.value);
    e.target.checked = true;
  };
  return (
    <StandardSection>
      <Title>What about your photos ?</Title>
      <Text>
        Selfies, photos of a parking space or a superb panoramic photo taken
        during a trek in the mountains, all our images accumulate in our
        catalogs, which are also duplicated, backed up... <br />
        How many photos, places and storage types do you have?
      </Text>
      <S.Container>
        <S.InputContainer>
          <S.QuestionContainer>
            <S.Icon alt="Number photos" src={PhotoTotal}></S.Icon>
            <S.Question>
              <TextInput>How many photos do you have ?</TextInput>
              <S.PhotoNumber
                placeholder="Number photos"
                value={numberPhotos}
                onChange={handleChangePhoto}
              ></S.PhotoNumber>
            </S.Question>
          </S.QuestionContainer>
          <S.QuestionContainer>
            <S.Icon alt="Storage Cloud" src={cloud}></S.Icon>
            <S.Question>
              <TextInput>Where do you store most of your photos ?</TextInput>
              <S.ContainerRadio onClick={handleClickStorage}>
                <S.InputRadio htmlFor="cloud">
                  <S.Input
                    type="radio"
                    id="cloud"
                    value="cloud"
                    name="storage"
                    defaultChecked={defaultCloud}
                  />
                  <S.TextInput>Cloud</S.TextInput>
                </S.InputRadio>
                <S.InputRadio htmlFor="HDD">
                  <S.Input
                    type="radio"
                    id="HDD"
                    value="HDD"
                    name="storage"
                    defaultChecked={HDDCheck}
                  />
                  <S.TextInput>Hard disk</S.TextInput>
                </S.InputRadio>
                <S.InputRadio htmlFor="SSD">
                  <S.Input
                    type="radio"
                    id="SSD"
                    value="SSD"
                    name="storage"
                    defaultChecked={SSDCheck}
                  />
                  <S.TextInput>SSD</S.TextInput>
                </S.InputRadio>
              </S.ContainerRadio>
            </S.Question>
          </S.QuestionContainer>
          <S.QuestionContainer>
            <S.Icon alt="Location" src={Geo}></S.Icon>
            <S.Question>
              <TextInput>Where in the world are you ?</TextInput>
              <SelectRegion setOperator={setOperator} />
            </S.Question>
          </S.QuestionContainer>
        </S.InputContainer>
        <ResultSection results={results} clarify={"cloud"} />
      </S.Container>
    </StandardSection>
  );
};
