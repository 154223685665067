import smartphone from "../assets/EcoWebSite/Smartphone.png";
import screen from "../assets/EcoWebSite/Screen.png";
import laptop from "../assets/EcoWebSite/Computer.png";
import computer from "../assets/EcoWebSite/DesktopComputer.png";
import tablet from "../assets/EcoWebSite/tablet.png";
import SSD from "../assets/EcoWebSite/SSD.png";
import HDD from "../assets/EcoWebSite/HDD.png";
import cloud from "../assets/EcoWebSite/cloud.png";

const Icons = (name) => {
  const icon =
    name === "smartphone"
      ? smartphone
      : name === "screen"
      ? screen
      : name === "computer"
      ? computer
      : name === "laptop"
      ? laptop
      : name === "tablet"
      ? tablet
      : name === "SSD"
      ? SSD
      : name === "HDD"
      ? HDD
      : name === "cloud"
      ? cloud
      : null;

  return icon;
};

export default Icons;
