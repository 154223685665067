import styled from "styled-components";

export const ContainerRadio = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InputRadio = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  cursor: pointer;
  width: 60px;
  border-radius: 0.5rem;
  transition: 200ms;
  &:hover {
    background-color: #222;
  }
`;

export const Input = styled.input`
  cursor: pointer;
`

export const PhotoNumber = styled.input`
  border: 1px solid #6cd8e5;
  border-radius: 1rem;
  background-color: #000;
  color: #fff;
  height: 1.5rem;
  width: 7rem;
  padding-left: 1rem;
  margin: 1rem 0 0 0;
  outline: none;
  transition: 200ms;
  -moz-appearance: textfield;
  &::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:hover {
    border-radius: 0.25rem;
  }
`;

export const Location = styled.select`
  border: 1px solid #6cd8e5;
  border-radius: 1rem;
  background-color: #000;
  color: #fff;
  height: 1.5rem;
  width: 8rem;
  padding-left: 1rem;
  margin: 1rem 0 0 0;
  outline: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  appearance: none;
  cursor: pointer;
  transition: 200ms;
  &:hover {
    border-radius: 0.25rem;
  }
`;

export const QuestionContainer = styled.div`
  display: flex;
  margin-top: 2rem;
  min-width: 265px;
  align-items: center;
  &:last-child {
    margin-bottom: 3rem;
  }
  @media (max-width: 425px) {
    min-width: none;
    &:last-child {
      margin-bottom: 2rem;
    }
  }
`;

export const Icon = styled.img`
  height: 3rem;
`;
export const Question = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  justify-content: center;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 425px) {
    flex-direction: column;
  }
`;

export const InputContainer = styled.div`
  position: relative;
  right: -20px;
  @media (max-width: 425px) {
    right: 0;
  }
`;

export const TextInput = styled.p`
  font-size: 12.4px;
  margin-top: 1rem;
`;
