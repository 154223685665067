export class DEVICE {
  constructor(
    id,
    name,
    className,
    lifeTime,
    commonUseTime,
    sharingOut,
    location,
    type
  ) {
    this.id = id;
    this.name = name;
    this.class = className;
    this.lifeTime = lifeTime;
    this.commonUseTime = commonUseTime;
    this.sharingOut = sharingOut;
    this.location = location;
    this.type = type;
  }
  set life(deviceLifeTime) {
    this.lifeTime = deviceLifeTime;
  }
  set useTime(deviceCommonUseTime) {
    this.commonUseTime = deviceCommonUseTime;
  }
  set sharing(deviceSharingOut) {
    this.sharingOut = deviceSharingOut;
  }
}
