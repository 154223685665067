const sharingOut = (devices) => {
  const className = ["user", "local", "remote"];
  className.forEach((name) => {
    const deviceSharing = devices.filter((device) => device.class === name);
    const sharingOut = 1 / deviceSharing.length;

    devices.forEach((element) => {
      if (element.class === name) element.sharingOut = sharingOut;
    });
  });
};

export default sharingOut;
