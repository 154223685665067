const share = (score, company) => {
  const langage = navigator.language;

  if (!company) return null;

  const text = {
    fr: `J'ai obtenu un EcoScore de ${score} avec le calculateur de Cyme !`,
    en: `I got an EcoScore of ${score} with the Cyme calculator!`,
  };
  const url = `https://eco.cyme.info/${score}`;
  //https://cyme.io/footprint-calculator-photographer-dev/
  const shareUrl = {
    twitter: {
      url: `https://twitter.com/intent/tweet?text=${
        text[langage] ?? text.en
      }&hashtags=cyme,ecology,photographer,digital,innovation&via=CymePhotography&url=${url}`,
      width: 650,
      height: 700,
    },
    facebook: {
      url: `https://www.facebook.com/v5.0/dialog/share?app_id=184484190795&href=${url}&display=page`,
      width: 650,
      height: 870,
    },
    linkedin: {
      url: `https://www.linkedin.com/sharing/share-offsite/?url=${url}`,
      width: 450,
      height: 600,
    },
  };

  return window.open(
    shareUrl[company].url,
    "targetWindow",
    `toolbar=no,
  location=no,
  status=no,
  menubar=no,
  scrollbars=yes,
  resizable=yes,
  left=${window.innerWidth / 2 - shareUrl[company].width / 2}},
  top=${window.innerHeight / 2 - shareUrl[company].height / 2}},
  width=${shareUrl[company].width},
  height=${shareUrl[company].height},`
  );
};

export default share;
