import React from "react";
import { Devices } from "./Devices";
import { AddDevice } from "./AddDevice";
import * as Input from "../styles/Input";
import * as Font from "../styles/Fonts";
import { StandardSection } from "../styles/Section";
import { ResultSection } from "./ResultSection";

export const ListDevices = ({
  listDevices,
  setListDevices,
  refresh,
  setRefresh,
  results,
}) => {
  return (
    <StandardSection>
      <Font.Title>What is your equipment ?</Font.Title>
      <Font.Text>
        Equipment is often the biggest contributor to our ecological footprint.
        What is your equipment made of?
      </Font.Text>

      <Input.ContainerDevice>
        <Input.Devices>
          {listDevices.map((device) => {
            return (
              <Devices
                key={device.id}
                device={device}
                listDevices={listDevices}
                setListDevices={setListDevices}
                refresh={refresh}
                setRefresh={setRefresh}
              />
            );
          })}
          <AddDevice
            listDevices={listDevices}
            setListDevices={setListDevices}
          ></AddDevice>
        </Input.Devices>
        <ResultSection results={results} clarify={"stuff"} />
      </Input.ContainerDevice>
    </StandardSection>
  );
};
