import React from "react";
import {
  Impact,
  ResultContainer,
  Icon,
  BigIcon,
  UnitText,
  ScoreText,
  TextContainer,
  TextEquipment,
} from "../../styles/Result";

export const Card = ({ impact, name, img, unit, type }) => {
  const isEquivalent = (impact, name) => {
    if (!impact) return null;
    if (name === "co2" || name === "h2o" || name === "mj" || name === "sb")
      return impact.toFixed(0);

    return impact.toFixed(0);
  };

  const display = type ? (
    <Impact color={name}>
      <Icon alt={`Icon of ${name}`} src={img}></Icon>
      <TextEquipment>{`${isEquivalent(impact, name) ?? 0} ${unit}`}</TextEquipment>
    </Impact>
  ) : (
    <ResultContainer>
      <BigIcon alt={`Icon of ${name}`} src={img} />
      <TextContainer>
        <UnitText>{unit}</UnitText>
        <ScoreText>{isEquivalent(impact, name) ?? 0}</ScoreText>
      </TextContainer>
    </ResultContainer>
  );

  return <>{display}</>;
};
