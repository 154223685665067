import styled from "styled-components";

export const Container = styled.div`
  margin-bottom: 2rem;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  &:hover > svg {
    opacity: 1;
  }
  @media (max-width: 425px) {
    justify-content: space-evenly;
    padding-right: 1rem;
  }
`;

export const Name = styled.p`
  font-size: 1.1rem;
`;

export const Result = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  border: 0.3rem solid green;
  background-color: #000;
  border-radius: 1.5rem;
  padding: 0.5rem;
  height: fit-content;
  position: relative;
  right: -3.5rem;
  margin-bottom: 2.5rem;
  @media (max-width: 425px) {
    right: 0rem;
  }
`;
