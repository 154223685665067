import styled from "styled-components";

export const UserLvl = styled.label((props) => ({
  opacity: props.opacity,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "1.5rem 0 3rem 0",
  transition: "200ms",
  ":hover": { opacity: 1, transform: "scale(1.05)" },
}));

export const IconUser = styled.img`
  height: 5.5rem;
  @media (max-width: 400px) {
    height: 3.5rem;
  }
`;

export const InputLvlGlobal = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ChoiceLvl = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
  margin-top: 2rem;
`;

export const Radio = styled.input`
  height: 1.5rem;
`;

export const Devices = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  position: relative;
  right: -20px;
  @media (max-width: 425px) {
    right: 0;
  }
`;

export const Lifetime = styled.input`
  border: none;
  border-bottom: 1px solid #6cd8e5;
  background-color: #000;
  margin: 0 0.3rem 0 0.2rem;
  color: #fff;
  max-width: 2.5ch;
  font-size: 1rem;
  text-align: center;
  outline: none;
  transition: 300ms;
  -moz-appearance: textfield;
  &::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const LifeContainer = styled.label`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  &:hover input {
    font-size: 1.2rem;
  }
  @media (max-width: 425px) {
    height: auto;
  }
`;

export const NameDevice = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 4rem;
`;

export const IconDevice = styled.img`
  height: 2.5rem;
  margin-bottom: 6px;
`;

export const ContainerDevice = styled.div`
  display: flex;
  justify-content: space-around;
  @media (max-width: 425px) {
    flex-direction: column;
  }
`;
