import styled from "styled-components";

export const Icon = styled.svg`
  cursor: pointer;
  user-select: none;
  padding-left: 0.3rem;
  opacity: 0;
  transition: 150ms;
  @media (max-width: 425px) {
    opacity: 1;
  }
`

// export const Icon = styled.svg((props) => ({
//    position: "absolute"
//   top: `calc(50% - ${props.height} / 2)`,
//   right: "-1.7rem",
//   cursor: "pointer",
//   userSelect: "none",
//   "@media (max-width: 425px)": {
//     right: "-0.6rem",
//   }
// }));
